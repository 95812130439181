<template>
  <div class="media">
     <navBar></navBar>
    <div class="content">

<!--      Media Text-->
      <textEdit title-user="Media Text" title-intern-prop="mediaText" site-prop="media"></textEdit>

    <!--Dropdown-->
      <div class="head" v-if="showHead">
        <md-field>
          <label for="year">Year</label>
          <md-select @md-selected="addYearDiv = false" v-model="currentYearIndex" name="Year" id="year">
            <md-option v-for="(year, index) in media" v-bind:key="year.year" :value="index">{{ year.year }}</md-option>
          </md-select>
        </md-field>
        <md-button class="md-primary md-raised" @click="showAddYearDiv()">Add Year</md-button>
      </div>

      <!-- See chosen year data -->
      <div class="yearData" v-if="!addYearDiv && currentYearIndex != null">
        <!-- Year-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Überschrift des Jahres</label>
                <md-input v-model="media[currentYearIndex].textDE.titleOfTheYear" ></md-input>
              </md-field>
              <md-field>
                <label>Text des Jahres</label>
                <md-textarea v-model="media[currentYearIndex].textDE.textOfTheYear"  md-autogrow ></md-textarea>
              </md-field>
              <br />
              <md-field>
                <label>Teilnehmer</label>
                <md-textarea v-model="newParticipant.de" placeholder="Neuer Teilnehmer" md-autogrow></md-textarea><md-button class="noMargin" @click="addParticipant('de', false)">Hinzufügen</md-button>
              </md-field>
              <div v-for="(participant, index) in media[currentYearIndex].textDE.participants" v-bind:key="participant" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span>{{ participant }}</span> <span @click="media[currentYearIndex].textDE.participants.splice(index, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Header of the Year</label>
                <md-input v-model="media[currentYearIndex].textEN.titleOfTheYear" ></md-input>
              </md-field>
              <md-field>
                <label>Content of the Year</label>
                <md-textarea v-model="media[currentYearIndex].textEN.textOfTheYear"  md-autogrow ></md-textarea>
              </md-field>
              <br />
              <md-field>
                <label>Participant</label>
                <md-textarea v-model="newParticipant.en" placeholder="New Participant" md-autogrow></md-textarea><md-button class="noMargin" @click="addParticipant('en', false)">Add</md-button>
              </md-field>
              <div v-for="(participant, index) in media[currentYearIndex].textEN.participants" v-bind:key="participant" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span>{{ participant }}</span><span @click="media[currentYearIndex].textEN.participants.splice(index, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>
          </tr>
        </table>

        <h3 class="md-subheader ">Videos</h3>
          <md-field>
            <label>Video</label>
            <md-input v-model="newVideo" placeholder="New Video" type="url"></md-input><md-button class="noMargin" @click="media[currentYearIndex].video.push(newVideo)">Add</md-button>
          </md-field>
        <div v-for="(video, index) in media[currentYearIndex].video" v-bind:key="video" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="video" target="_blank">{{ video }}</a><span @click="media[currentYearIndex].video.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <h3 class="md-subheader">Audio</h3>
        <md-field>
          <label>Audio</label>
          <md-input v-model="newAudio.name" placeholder="Audio Name" type="text"></md-input><md-input v-model="newAudio.link" placeholder="Audio Link" type="url"></md-input><md-button class="noMargin" @click="media[currentYearIndex].audio.push(JSON.parse(JSON.stringify(newAudio)))">Add</md-button>
        </md-field>
        <div v-for="(audio, index)  in media[currentYearIndex].audio" v-bind:key="audio.name" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="audio.link" target="_blank">{{ audio.name }}</a><span @click="media[currentYearIndex].audio.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <h3 class="md-subheader">Images</h3>
        <md-field>
          <label>Image</label>
          <md-input v-model="newImage.alt" placeholder="Description" type="text"></md-input><md-input v-model="newImage.link" placeholder="Image Link" type="url"></md-input><md-button class="noMargin" @click="media[currentYearIndex].images.push(JSON.parse(JSON.stringify(newImage)))">Add</md-button>
        </md-field>
        <div v-for="(image, index)  in media[currentYearIndex].images" v-bind:key="image.alt" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="image.link" target="_blank">{{ image.alt }}</a><span @click="media[currentYearIndex].images.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <h3 class="md-subheader">Downloads</h3>
        <md-field>
          <label>Downloads</label>
          <md-input v-model="newDownload.alt" placeholder="Description" type="text"></md-input>
          <md-input v-model="newDownload.link" placeholder="Download Link" type="url"></md-input>
          <md-button class="noMargin" @click="media[currentYearIndex].downloads.push(JSON.parse(JSON.stringify(newDownload)))">Add</md-button>
        </md-field>
        <div v-for="(download, index)  in media[currentYearIndex].downloads" v-bind:key="download.alt" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="download.link" target="_blank">{{ download.alt }}</a><span @click="media[currentYearIndex].downloads.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>
        <br />
        <md-dialog-confirm
          :md-active.sync="newYearDialogActive"
          :md-title="'Delete year ' + media[currentYearIndex].year + '?'"
          md-content="All media of this year will be completely deleted!"
          md-confirm-text="Delete"
          md-cancel-text="Don't delete"
          @d-cancel="deleteYearDialogActive = false"
          @md-confirm="deleteYear(media[currentYearIndex]._id)" />

        <md-button class="md-accent md-raised" @click="newYearDialogActive = true">Delete Year</md-button>
        <md-button class="md-primary md-raised" @click="putYear()">Save Changes</md-button>

      </div>

      <!-- Add Year -->
      <div class="addYear" v-if="addYearDiv">
        <!-- Year-->
        <md-field>
          <md-icon>event</md-icon>
          <label>Year (e.g. 2020)</label>
          <md-input v-model="newYear.year" type="number" maxlength="4" required></md-input>
        </md-field>

        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Überschrift des Jahres</label>
                <md-input v-model="newYear.textDE.titleOfTheYear"></md-input>
              </md-field>
              <md-field>
                <label>Text des Jahres</label>
                <md-textarea v-model="newYear.textDE.textOfTheYear" md-autogrow ></md-textarea>
              </md-field>
              <br />
              <md-field>
                <label>Teilnehmer</label>
                <md-textarea v-model="newParticipant.de" placeholder="Neuer Teilnehmer" md-autogrow></md-textarea><md-button class="noMargin" @click="addParticipant('de', true)">Hinzufügen</md-button>
              </md-field>
              <div v-for="(participant, index) in newYear.textDE.participants" v-bind:key="participant" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span>{{ participant }}</span> <span @click="newYear.textDE.participants.splice(index, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Header of the Year</label>
                <md-input v-model="newYear.textEN.titleOfTheYear"></md-input>
              </md-field>
              <md-field>
                <label>Content of the Year</label>
                <md-textarea v-model="newYear.textEN.textOfTheYear" md-autogrow></md-textarea>
              </md-field>
              <br />
              <md-field>
                <label>Participant</label>
                <md-textarea v-model="newParticipant.en" placeholder="New Participant" md-autogrow></md-textarea><md-button class="noMargin" @click="addParticipant('en', true)">Add</md-button>
              </md-field>
              <div v-for="(participant, index) in newYear.textEN.participants" v-bind:key="participant" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span>{{ participant }}</span> <span @click="newYear.textEN.participants.splice(index, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>
          </tr>
        </table>
        <p>For inserting Videos, please make sure, to copy the embedded link. To get this link, go to YouTube, click your video and click share. Than click on "embedded". Make sure to only copy the link (e.g. https://www.youtube.com/embed/OPhkZW_jwc0)</p>
        <md-field>
          <label>Video</label>
          <md-input v-model="newVideo" placeholder="New Video" type="url"></md-input><md-button class="noMargin" @click="addVideo()">Add</md-button>
        </md-field>
        <div v-for="(video, index) in newYear.video" v-bind:key="index" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="video" target="_blank">{{ video }}</a> <span @click="newYear.video.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <md-field>
          <label>Audio</label>
          <md-input v-model="newAudio.name" placeholder="Audio Name" type="text"></md-input><md-input v-model="newAudio.link" placeholder="Audio Link" type="url"></md-input><md-button class="noMargin" @click="addAudio()">Add</md-button>
        </md-field>
        <div v-for="(audio, index) in newYear.audio" v-bind:key="index" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="audio.link" target="_blank">{{ audio.name }}</a> <span @click="newYear.audio.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <md-field>
          <label>Image</label>
          <md-input v-model="newImage.alt" placeholder="Description" type="text"></md-input><md-input v-model="newImage.link" placeholder="Image Link" type="url"></md-input><md-button class="noMargin" @click="addImage()">Add</md-button>
        </md-field>
        <div v-for="(image, index) in newYear.images" v-bind:key="index" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="image.link" target="_blank">{{ image.alt }}</a> <span @click="newYear.images.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

        <md-field>
          <label>Downloads</label>
          <md-input v-model="newDownload.alt" placeholder="Download Description" type="text"></md-input><md-input v-model="newDownload.link" placeholder="Download Link" type="url"></md-input><md-button class="noMargin" @click="addDownload()">Add</md-button>
        </md-field>
        <div v-for="(download, index) in newYear.images" v-bind:key="index" style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
          <a :href="download.link" target="_blank">{{ download.alt }}</a> <span @click="newYear.downloads.splice(index, 1)"><md-icon>delete</md-icon></span>
        </div>

          <md-dialog-confirm
            :md-active.sync="newYearDialogActive"
            md-title="Do you want to add a new year?"
            md-content="A new data set will be created."
            md-confirm-text="Save"
            md-cancel-text="Cancel"
            @d-cancel="newYearDialogActive = false"
            @md-confirm="pushNewYear()" />

          <md-button class="md-primary md-raised" @click="newYearDialogActive = true">Add Year</md-button>

          <md-dialog-confirm
            :md-active.sync="newYearCancelDialogActive"
            md-title="Do you want to delete all entered data?"
            md-content="All data you entered will be deleted."
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @d-cancel="newYearCancelDialogActive = false"
            @md-confirm="resetSite()" />

          <md-button class="md-accent md-raised" @click="newYearCancelDialogActive = true">Cancel</md-button>
          <loading :active.sync="query"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
        <md-snackbar md-position="center" :md-duration="400000" :md-active="showSnackbar" md-persistent>
          <span>{{ snackbarContent }}</span>
        </md-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
    import navBar from '@/components/NavBar.vue';
    import axios from 'axios';
    import textEdit from '@/components/TextEdit.vue';

    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import config from '@/config/const.js'

    export default {
        name: "Home",
        data () {
            return {
              config: config,
              query: false,
              newYearDialogActive: false,
              newYearCancelDialogActive: false,
              deleteYearDialogActive: false,
              addYearDiv: false,
              showHead: true,
              currentYearIndex: null,

              showSnackbar: false,
              snackbarContent: "",

              media: null,
              newYear: {
                "year": new Date().getFullYear(),
                "textDE": {
                  "titleOfTheYear": "",
                  "textOfTheYear": "",
                  "participants": []
                },
                "textEN": {
                  "titleOfTheYear": "",
                  "textOfTheYear": "",
                  "participants": []
                },
                "video": [],
                "audio": [],
                "images": [],
                "downloads": []
              },
              newParticipant:{
                "de": "",
                "en": ""
              },
              newVideo: "",
              newAudio:{
                "name": "",
                "link": ""
              },
              newImage:{
                "alt": "",
                "link": ""
              },
              newDownload:{
                "alt": "",
                "link": ""
              }

            }
        },
        methods:{
          pushNewYear(){
            this.query = true;
            let me = this;
            axios.post( me.config.SERVER_ADDRESS + '/media/',me.newYear)
                .then(function (response) {
                  me.loadData()
                  me.resetSite()
                  me.snackbarContent = response.data.message
                  me.showSnackbar = true
                  me.query = false;
                })
                .catch(function (error) {
                  me.query = false;
                  alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
                })
          },
          putYear(){
            this.query = true;
            let me = this;
            axios.put( me.config.SERVER_ADDRESS + '/media/' + me.media[me.currentYearIndex]._id,me.media[me.currentYearIndex])
              .then(function (response) {
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.loadData()
                me.resetSite()
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              })
          },
          deleteYear(id){
            this.query = true;

            let me = this;
            axios.delete( me.config.SERVER_ADDRESS + '/media/' + id)
              .then(function (response) {
                me.loadData();
                me.resetSite();
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          resetSite(){
            this.newYearDialogActive = false
            this.newYearCancelDialogActive = false
            this.addYearDiv = false
            this.showHead = true
            this.currentYearIndex = null
            this.newYear = {
              "year": new Date().getFullYear(),
                "textDE": {
                "titleOfTheYear": "",
                  "textOfTheYear": "",
                  "participants": []
              },
              "textEN": {
                "titleOfTheYear": "",
                  "textOfTheYear": "",
                  "participants": []
              },
              "video": [],
                "audio": [],
                "images": []
            }
            this.newParticipant={
              "de": "",
                "en": ""
            }
            this.newVideo=""
            this.newAudio={
              "name": "",
              "link": ""
            }
            this.newImage={
              "alt": "",
                "link": ""
            }
          },
          showAddYearDiv(){
            this.addYearDiv = true
            this.showHead = false
          },
          loadData: function () {
            const me = this
            axios.get( me.config.SERVER_ADDRESS + '/media')
              .then(function (response) {
                me.media = response.data
              })
              .catch(function (error) {
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          addParticipant(language, isNew){
            if(isNew) {
              switch (language) {
                case 'de':
                  this.newYear.textDE.participants.push(this.newParticipant.de);
                  this.newParticipant.de = "";
                  break;
                case 'en':
                  this.newYear.textEN.participants.push(this.newParticipant.en);
                  this.newParticipant.en = "";
                  break;
              }
            }
            else{
              switch (language) {
                case 'de':
                  this.media[this.currentYearIndex].textDE.participants.push(this.newParticipant.de);
                  this.newParticipant.de = "";
                  break;
                case 'en':
                  this.media[this.currentYearIndex].textEN.participants.push(this.newParticipant.en);
                  this.newParticipant.en = "";
                  break;
              }
            }
          },
          addVideo(){
            this.newYear.video.push(this.newVideo);
            this.newVideo = "";
          },
          addAudio(){
            this.newYear.audio.push(this.newAudio);
            this.newAudio = {
              "name": "",
              "link": ""
            }
          },
          addImage(){
            this.newYear.images.push(this.newImage);
            this.newImage = {
              "alt": "",
              "link": ""
            }
          },
          addDownload(){
            this.newYear.downloads.push(this.newDownload);
            this.newDownload = {
              "alt": "",
              "link": ""
            }
          },
        },
        components: {
          navBar,
          Loading,
          textEdit
        },
        mounted(){
          this.loadData();
        }
    }
</script>
<style scoped>
  .content{
    margin: 2% 5% 0 5%;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  td{
    padding: 1%;
    width: 50%;
  }
  .noMargin{
    margin: 0px;
  }
</style>
